/*!

 =========================================================
 * Styles personalizados
 =========================================================
*/

.card-pay {
    box-shadow: 0 9px 20px 32px rgb(0, 19 0, 23%);
    /* border-radius: 17px; */
}

.card.card-coin {
    border: 2px solid #f5b442;
}

.nav-tabs .nav-item .nav-linkpay:hover {
    background-color: transparent;
    /*border: 1px solid #191f31;*/
    color: rgba(255, 255, 255, 0.8);
}

.nav-tabs.nav-tabs-danger > .nav-linkpay {
    border-color: #ffffff;
    color: rgba(255, 255, 255, 0.8);
}

.fc-val {
    border-color: #edeef3;
    color: #ffffff;
}

.color-mssr {
    color: #ffffff;
}

.register.card {
    background: rgba(72, 70, 70, 0.32);
}

.btn-grad {
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    background: rgb(237, 107, 56);
    background: linear-gradient(90deg, rgba(237, 107, 56, 0.9869622564935064) 38%, rgba(232, 5, 5, 1) 98%);
    color: white;
    box-shadow: 0 0 11px #7b7878;
    border-radius: 10px;
    display: block;
    border-width: 0;
}

.btn-grad-to {
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    background: rgb(237, 107, 56);
    background: linear-gradient(90deg, rgba(237, 107, 56, 0.9869622564935064) 38%, rgba(232, 5, 5, 1) 98%);
    color: white;
    box-shadow: 0 0 11px #7b7878;
    border-radius: 10px;
    display: block;
    border-width: 0;
}

.text-blg {
    color: #080808 !important;
}

.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1100px;
}

.img-ctd {
    background-size: cover;
    background-image: url("assets/img/background/citadu-s.png");
    background-position: center center;
    width: 100%;
    z-index: -1;
    opacity: 0.7;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding: 0;
    color: #ffffff;
    overflow: hidden;
}

#buysuscription .nav-tabs > .nav-item > .nav-link {
    font-weight: 500;
}
#buysuscription .nav-tabs > .nav-item > .nav-link:hover {
    border: 1px solid #3c3a3a;
}

#buysuscription .nav-tabs.nav-tabs-primary > .nav-item > .nav-link.active {
    border-color: #f5b442;
    color: #ffffff;
    background: rgb(253, 229, 139);
    background: linear-gradient(90deg, rgba(253, 229, 139, 1) 9%, rgba(243, 166, 51, 1) 44%);
}

/* INICIO @Media buysuscription*/
#buysuscription .nav-item-plan {
    padding-right: 6px;
}

.card {
    background: rgb(72, 70, 70, 40%);
}

#buysuscription .bcplan {
    background-color: transparent;
}

#buysuscription .card-pricing ul li {
    padding: 3px 0;
    font-size: 17px;
}

#buysuscription .img-plan {
    width: 66px;
    height: 104px;
}

#buysuscription .nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link.active:focus,
.nav-pills .nav-item .nav-link.active:hover {
    background-color: transparent;
    color: #ffffff;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 40%);
    /*transform: translateY(-15px);*/
    width: 76px;
    height: 85px;
    
}


#buysuscription .nav-pills .nav-item .nav-link.active .img-plan ,
.nav-pills .nav-item .nav-link.active:focus .img-plan ,
.nav-pills .nav-item .nav-link.active:hover .img-plan {
    border-bottom: 2px solid #f3a633;
}

#buysuscription .nav-pills .nav-item .nav-link {
    background-color: transparent;
}

#buysuscription .nav-pills .nav-item .nav-link:hover {
    transform: translateY(-6px);
}

#buysuscription .nav-pills .nav-item .nav-link:focus {
    transform: translateY(-11px);
}

#buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
    width: 70px;
    height: 111px;
    border-bottom: 2px solid #f3a633;
}

#buysuscription .react-select.react-select-info .react-select__control {
    border-color: #f2a133;
}

#buysuscription .title-cat {
    color: #f2a133;
}

#buysuscription
    .react-select.react-select-info
    .react-select__control
    .react-select__value-container--has-value
    .react-select__single-value,
.react-select.react-select-info
    .react-select__control
    .react-select__value-container--has-value
    .react-select__multi-value__label {
    color: #f2a133;
}

#checkout #tables .table-responsive {
    margin-bottom: 10px;
}

#checkout .table-shopping .img-container {
    width: 36px;
}

#checkout .alert {
    background-color: #b68223;
    padding: 0.5rem 1.25rem !important;
}

#checkout .table {
    background-color: rgb(29, 28, 28);
}
#checkout .card {
    background: rgb(30 28 28);
}

#checkout .text-danger {
    color: #ff1248 !important;
}

#checkout .text-success {
    color: #fbff03 !important;
}

#checkout .contact {
    border-color: #ffffff;
}

#checkout .section {
    padding: 33px 0;
    position: relative;
}

#checkout .line-warning {
    background: rgb(237, 107, 56);
    background: linear-gradient(90deg, rgba(237, 107, 56, 0.9869622564935064) 38%);
}

#shopping .table-shopping .img-container {
    width: 36px;
}

#contentAreas .card {
    background: rgb(30 28 28);
}

#contentAreas .table {
    background-color: rgb(29, 28, 28);
}

#contentAreas #tables .table-responsive {
    margin-bottom: 10px;
}

#checkoutPayment .card.card-coin {
    border: 1px solid #ffffff;
}

#checkoutPayment .card {
    background-color: rgb(31 29 29 / 28%);
}

#checkoutPayment .chkp {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

#checkoutPayment .icon-credit-card {
    margin-right: 5px;
    font-size: 38px;
    color: #fcfcfc;
    margin-top: -3px;
}

#checkoutPayment .icon-confirmation {
    margin-right: 5px;
    font-size: 104px;
    color: #00bf9a;
    margin-top: -3px;
}
#checkoutPayment .icon-danger {
    margin-right: 5px;
    font-size: 104px;
    color: #da2304;
    margin-top: -3px;
}

#transfer .text-transfer {
    font-family: none;
    color: #ffffff;
}
#transfer .card-background[data-animation="zooming"]:hover {
    transform: scale(1.01);
    /* -webkit-transform: scale(1.07); */
    z-index: 1;
}

#TransbankPay .img-tranbk {
    background: rgb(255 255 255);
}

#transfer .card {
    background-color: rgb(31 29 29 / 28%);
}

@media screen and (max-width: 1024px) {
    #buysuscription .card .card-body {
        padding: 7px;
    }
}

@media screen and (max-width: 991px) {
    #buysuscription .nav-tabs .nav-item .nav-link {
        /*padding: 13px 19px;*/
    }
}

@media screen and (max-width: 768px) {
    #buysuscription .nav-tabs .nav-item {
        display: inline-block;
    }
    #TransbankPay .modalExp > div > div {
        width: 400px !important;
    }
}

@media screen and (max-width: 540px) {
    #buysuscription .card .card-body {
        padding: 13px;
    }
    #buysuscription .nav-tabs > .nav-item > .nav-link {
        margin-right: 19px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 72px;
        height: 109px;
        border-bottom: 2px solid #f3a633;
    }
}

@media screen and (max-width: 501px) {
    #buysuscription .card-pricing .card-title {
        font-size: 30px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 68px;
        height: 78px;
        border-bottom: 2px solid #f3a633;
    }
}

@media screen and (max-width: 499px) {
    #buysuscription .nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
        margin-right: 0px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 68px;
        height: 78px;
        border-bottom: 2px solid #f3a633;
    }
}

@media screen and (max-width: 495px) {
    #buysuscription .nav-tabs > .nav-item > .nav-link {
        padding: 13px 19px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 68px;
        height: 85px;
        border-bottom: 2px solid #f3a633;
    }
    #buysuscription .nav-item-plan {
        padding: 2px;
    }
}
@media screen and (max-width: 488px) {
    #buysuscription .nav-item-plan {
        padding: 2px;
    }
}

@media screen and (max-width: 483px) {
    #buysuscription .nav-item-plan {
        padding: 1px;
    }
    #buysuscription .img-plan {
        width: 60px;
        height: 95px;
    }
}
@media screen and (max-width: 473px) {
    #buysuscription .nav-item-plan {
        padding: 10px;
    }
    #buysuscription .img-plan {
        width: 51px;
        height: 82px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 57px;
        height: 88px;
        border-bottom: 2px solid #f3a633;
    }
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link .tim-icons {
        font-size: 20px;
        line-height: 50px;
    }
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link {
        height: 50px;
        width: 50px;
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 414px) {
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link .tim-icons {
        font-size: 20px;
        line-height: 50px;
    }
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link {
        height: 50px;
        width: 50px;
        font-size: 0.7rem;
    }

    #buysuscription .img-plan {
        width: 46px;
        height: 72px;
    }

    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        transform: scale(1.0);
        width: 47px;
        height: 80px;
        border-bottom: 2px solid #f3a633;
    }

    #buysuscription .nav-tabs > .nav-item > .nav-link {
        margin-right: 19px;
        padding: 4px 8px;
    }

    #buysuscription .nav-item-plan {
        padding: 8px;
    }

    #checkoutPayment .paymentMsg {
        padding: 4px;
    }

    #TransbankPay .modalExp > div > div {
        width: 363px !important;
    }
}
@media screen and (max-width: 411px) {
    #buysuscription .nav-item-plan {
        padding: 8px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 48px;
        height: 76px;
        border-bottom: 2px solid #f3a633;
    }

    #buysuscription .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover {
        width: 48px;
    }

    #buysuscription .img-plan {
        width: 44px;
        height: 69px;
    }

    #TransbankPay .modalExp > div > div {
        width: 340px !important;
    }
}

@media screen and (max-width: 393px) {
    #buysuscription .nav-item-plan {
        padding: 7px;
    }
}
@media screen and (max-width: 383px) {
    #buysuscription .nav-item-plan {
        padding: 5px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 51px;
        height: 76px;
        border-bottom: 2px solid #f3a633;
    }
}

@media screen and (max-width: 375px) {
    #buysuscription .nav-tabs > .nav-item > .nav-link {
        margin-right: 19px;
        padding: 4px 8px;
    }
    #buysuscription .nav-item-plan {
        padding: 6px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        transform: scale(1.0);
        width: 48px;
        height: 72px;
        border-bottom: 2px solid #f3a633;
    }
    #TransbankPay .modalExp > div > div {
        width: 330px !important;
    }
}
@media screen and (max-width: 373px) {
    #buysuscription .img-plan {
        width: 49px;
        height: 77px;
    }
    #buysuscription .nav-item-plan {
        padding: 5px;
    }
}

@media screen and (max-width: 362px) {
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link .tim-icons {
        font-size: 20px;
        line-height: 50px;
    }
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link {
        height: 50px;
        width: 50px;
        font-size: 0.7rem;
    }

    #buysuscription .card-pricing .card-title {
        font-size: 25px;
    }

    #buysuscription .h3 {
        font-size: 1.3rem;
    }

    #buysuscription .img-plan {
        width: 44px;
        height: 68px;
    }
    #buysuscription .nav-item-plan {
        padding: 3px;
    }

    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 68px;
        height: 77px;
        border-bottom: 2px solid #f3a633;
    }

    #validateSubscription .btn-lg {
        padding: 9px 9px;
    }

    #TransbankPay .modalExp > div > div {
        width: 300px !important;
    }
}

@media screen and (max-width: 342px) {
    #buysuscription .nav-item-plan {
        padding: 2px;
    }
    #buysuscription .img-plan {
        width: 43px;
        height: 65px;
    }
}
@media screen and (max-width: 333px) {
    #buysuscription .nav-item-plan {
        padding: 0px;
    }
    #buysuscription .img-plan {
        width: 43px;
        height: 63px;
    }
    /*#buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 43px;
        height: 63px;
    }*/
}

@media screen and (max-width: 320px) {
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link .tim-icons {
        font-size: 20px;
        line-height: 40px;
    }
    #buysuscription .nav-pills.nav-pills-just-icons .nav-item .nav-link {
        height: 40px;
        width: 40px;
        font-size: 0.65rem;
    }

    #buysuscription .h3 {
        font-size: 1rem;
    }
    #buysuscription .card .card-body {
        padding: 9px;
    }
    #buysuscription .img-plan {
        /* width: 66px; */
        height: 63px;
    }
    #buysuscription .nav-item-plan {
        padding: 6px;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 68px;
        height: 68px;
        border-bottom: 2px solid #f3a633;
    }
    #TransbankPay .modalExp > div > div {
        width: 265px !important;
    }
}

@media screen and (max-width: 315px) {
    #buysuscription .card-pricing .card-title {
        font-size: 16px;
    }

    #buysuscription .card-pricing ul li i {
        font-size: 12px;
    }

    #buysuscription .card .card-body {
        padding: 6px;
    }
    #buysuscription .img-plan {
        /* width: 66px; */
        height: 59px;
    }

    #buysuscription .h3 {
        font-size: 1.1rem;
    }
    #buysuscription .nav-tabs > .nav-item > .nav-link {
        margin-right: 11px;
    }
    #buysuscription .nav-item-plan {
        padding: 3px;
    }

    #validateSubscription .btn-lg {
        padding: 4px 15px;
    }

    #checkoutPayment .pMsg {
        font-size: small;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 70px;
        height: 64px;
        border-bottom: 2px solid #f3a633;
    }
    #validateSubscription .card .card-body {
        padding: 1px;
    }
    #TransbankPay .modalExp > div > div {
        width: 245px !important;
    }
}

@media screen and (max-width: 280px) {
    #buysuscription .card-pricing .card-title {
        font-size: 16px;
    }

    #buysuscription .card-pricing ul li i {
        font-size: 12px;
    }

    #buysuscription .card .card-body {
        padding: 6px;
    }
    #buysuscription .img-plan {
        /* width: 66px; */
        height: 59px;
    }

    #buysuscription .h3 {
        font-size: 1.1rem;
    }
    #buysuscription .nav-tabs > .nav-item > .nav-link {
        margin-right: 11px;
    }
    #buysuscription .nav-item-plan {
        padding: 3px;
    }

    #validateSubscription .btn-lg {
        padding: 4px 15px;
    }

    #checkoutPayment .pMsg {
        font-size: small;
    }
    #buysuscription .nav-pills .nav-item .nav-link:focus .img-plan {
        width: 70px;
        height: 64px;
        border-bottom: 2px solid #f3a633;
    }
    #validateSubscription .card .card-body {
        padding: 1px;
    }
    #TransbankPay .modalExp > div > div {
        width: 245px !important;
    }
}
